import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"
/**
 * @description: 政策职位管理列表
 * @param {*} data page:当前页  size:当前条数 projectId:项目ID positionName:职位名称
 * @return {*}
 */
export function positionList (data) {
  return fetchApi(URL.POSITION_LIST, data, 'get','params',true)
}
/**
 * @description: 政策职位管理添加
 * @param {*} data
 * @return {*}
 */
export function positionAdd (data) {
  return fetchApi(URL.POSITION_ADD, data, 'post', 'data')
}
/**
 * @description: 政策职位管理编辑
 * @param {*} data
 * @return {*}
 */
export function positionEdit (data) {
  return fetchApi(URL.POSITION_EDIT, data, 'put', 'data')
}
/**
 * @description: 政策职位管理启用/禁用
 * @param {*}   id 职位ID
 * @param {*} positionStatus 职位状态
 * @return {*}
 */
export function positionEnable (data) {
  return fetchApi(URL.POSITION_ENABLE, data, 'put', 'data')
}
/**
 * @description: 政策职位管理删除
 * @param {*} positionId 职位ID
 * @return {*}
 */
export function positionDelete (positionId) {
  return fetchApi(URL.POSITION_DELETE + '/' + positionId, null, 'delete')
}
/**
 * @description: 政策获取所有职位
 * @return {*}
 */
export function getAllPosition () {
  return fetchApi(URL.GET_ALL_POSITION, null, 'get')
}
/**
 * @description: 获取所属项目
 * @return {*}
 */
export function getProjectList () {
  return fetchApi(URL.GET_ALL_PROJECT_LIST, null, 'get')
}
/**
 * @description: 政策职位详情
 * @param {*} id 职位ID
 * @return {*}
 */
export function positionDetail (id) {
  return fetchApi(URL.POSITION_DETAIL + '/' + id, null, 'get')
}
/**
 * @description: 服务管理职位管理列表
 * @param {*} data page:当前页  size:当前条数 projectId:项目ID positionName:职位名称
 * @return {*}
 */
export function serverPositionList (data) {
  return fetchApi(URL.POSITION_CONSULT_LIST, data, 'get','params',true)
}
/**
 * @description: 服务管理职位管理添加
 * @param {*} data
 * @return {*}
 */
export function serverPositionAdd (data) {
  return fetchApi(URL.POSITION_CONSULT_ADD, data, 'post', 'data')
}
/**
 * @description: 服务管理职位管理编辑
 * @param {*} data
 * @return {*}
 */
export function serverPositionEdit (data) {
  return fetchApi(URL.POSITION_CONSULT_EDIT, data, 'put', 'data')
}
/**
 * @description: 服务管理职位管理启用/禁用
 * @param {*}   id 职位ID
 * @param {*} positionStatus 职位状态
 * @return {*}
 */
export function serverPositionEnable (data) {
  return fetchApi(URL.POSITION_CONSULT_ENABLE, data, 'put', 'data')
}
/**
 * @description: 服务管理职位管理删除
 * @param {*} positionId 职位ID
 * @return {*}
 */
export function serverPositionDelete (positionId) {
  return fetchApi(URL.POSITION_CONSULT_DELETE + '/' + positionId, null, 'delete')
}
/**
 * @description: 服务管理职位详情
 * @param {*} id 职位ID
 * @return {*}
 */
export function serverPositionDetail (id) {
  return fetchApi(URL.POSITION_CONSULT_DETAIL + '/' + id, null, 'get')
}
// 顺义获取项目接口
export function getShunyiAllProject(data) {
  return fetchApi(URL.GET_SHUNYI_ALL_PROJECTLIST, data, 'post', 'data',true);
}